import React, { Component } from "react"
import styled from "@emotion/styled"

const StyledLink = styled("div")`
  display: flex;
  text-align: left;
  align-self: flex-start;
  cursor: pointer;
  position: relative;
  width: 100%;
  align-items: center;
  padding-bottom: 12vw;
  padding-top: 6vw;
  padding-left: 4vw;
`

class BackButtonMobile extends Component {
  render() {
    const { color } = this.props

    return (
      <StyledLink onClick={() => { window && typeof window !== `undefined` && window.history.go(-1) }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="5vw"
          viewBox="0 0 543.5 301.89"
        >
          <path
            fill={color}
            stroke={color}
            strokeMiterlimit="10"
            strokeWidth="30"
            d="M543.5 150.95H36.07"
          />
          <path
            d="M241.11 6.96a15 15 0 01-4.62 20.71L42.92 150.95 236.5 274.23a15.001 15.001 0 01-16.11 25.31L7 163.61a15 15 0 010-25.32L220.37 2.36a15 15 0 0120.71 4.57z"
            fill={color}
          />
        </svg>
      </StyledLink>
    )
  }
}

export default BackButtonMobile
