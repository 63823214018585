import React, { Component, Fragment } from "react"
import { Above, Below } from "../Breakpoints"
import { Helmet } from "react-helmet"
import ProjectMobile from "../components/projectMobile"
import ProjectDesktop from "../components/projectDesktop/index.js"
import { navigate } from "gatsby"
import ProjectFullPage from "../components/projectFullPage"

class Project extends Component {
  componentDidMount() {
    const data = this.props.location.state && this.props.location.state.item
    const frontmatter = data && data.node.frontmatter
    !frontmatter && navigate("/")

    const bg_color = frontmatter && frontmatter.bg_color

    bg_color &&
      document &&
      document.body.style.setProperty("background-color", `${bg_color}`)
  }

  render() {
    const data = this.props.location.state && this.props.location.state.item

    const frontmatter = data && data.node.frontmatter

    const listMobile = frontmatter && frontmatter.imageListMobile
    const listDesktop = frontmatter && frontmatter.imageListDesktop
    const title = frontmatter && frontmatter.title
    const fullPage = frontmatter && frontmatter.fullPage
    const threeImages = frontmatter && frontmatter.threeImages
    const text_color = frontmatter && frontmatter.text_color
    const heroDesktop = data && data.node && data.node.heroImageDesktop
    const desc1 = frontmatter && frontmatter.description1
    const desc2 = frontmatter && frontmatter.description2
    const desc3 = frontmatter && frontmatter.description3

    return frontmatter !== null ? (
      <Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="wrapper">
          {fullPage ? (
            <ProjectFullPage
              text_color={text_color}
              title={title}
              desc1={desc1}
              desc2={desc2}
              desc3={desc3}
              heroDesktop={heroDesktop}
              listDesktop={listDesktop}
              listMobile={listMobile}
              fullPage={fullPage}
            ></ProjectFullPage>
          ) : (
            <Fragment>
              <Above
                breakpoint="md"
                height="100%"
                width="100%"
                position="relative"
              >
                <ProjectDesktop
                  text_color={text_color}
                  title={title}
                  desc1={desc1}
                  desc2={desc2}
                  desc3={desc3}
                  heroDesktop={heroDesktop}
                  list={listDesktop}
                  fullPage={fullPage}
                  threeImages={threeImages}
                ></ProjectDesktop>
              </Above>

              <Below breakpoint="md" height="100%" width="100%">
                <ProjectMobile
                  text_color={text_color}
                  title={title}
                  desc1={desc1}
                  desc2={desc2}
                  desc3={desc3}
                  list={listMobile}
                  fullPage={fullPage}
                ></ProjectMobile>
              </Below>
            </Fragment>
          )}
        </div>
      </Fragment>
    ) : null
  }
}

export default Project
