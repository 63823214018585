import React from "react"
import styled from "@emotion/styled"

const Text = styled("p")`
  font-family: "Helvetica Neue", sans-serif;
  position: ${props => (props.p ? props.p : "relative")};
  bottom: ${props => (props.p ? "0" : null)};
  right: ${props => (props.p ? "6vw" : null)};
  font-size: 2vw;
  line-height: 1;
  margin-bottom: ${props => (props.mb ? props.mb : null)};
  margin-right: ${props => (props.mr ? props.mr : null)};
  text-align: ${props => (props.alignText ? props.alignText : "left")};
  text-decoration: ${props => (props.underline ? "underline" : "none")};
  @media screen and (max-width: 1024px) {
    font-size: 18px;
    line-height: 1.2;
  }
  color: ${props => (props.color ? props.color : "black")};
`

const SmallText = ({ text, ...props }) => (
  <Text
    {...props}
    dangerouslySetInnerHTML={{
      __html: text,
    }}
  ></Text>
)

export default SmallText
