import React from "react"
import styled from "../components/styled"

const Img = styled("img")``

const Image = ({ wrapperStyle, src, ...rest }) => {
  return <Img alt="img" src={src} style={{ ...wrapperStyle }} {...rest} />
}

export default Image
