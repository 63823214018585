import React, { Component, Fragment } from "react"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"
import BackButtonDesktop from "../backButtonDesktop"
import BigText from "../text/bigtext"
import SmallText from "../text/smalltext"
import GatsbyImage from "../gatsbyImage"
import BaseImage from "../baseImage"
import ThreeImages from "./threeImages"

const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 300ms ease-in;
  animation-fill-mode: forwards;
  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    10% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }
`

const ColumnWrapper = styled("div")`
  position: relative;
  width: 100%;
  margin-top: ${props => (props.mt ? props.mt : null)};
  margin-bottom: ${props => (props.mb ? props.mb : null)};
  display: flex;
  align-items: ${props => (props.alignItems ? props.alignItems : null)};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : null};
  padding-right: ${props => (props.pr ? props.pr : null)};
  min-height: ${props => (props.height ? props.height : null)};
  @media screen and (max-width: 1025px) {
    min-height: ${props => (props.first ? "70vh" : null)};
  }
`
const RightColumn = styled("div")`
  position: relative;
  display: flex;
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : "column"};
  width: ${props => (props.width ? props.width : "40%")};
  padding-top: ${props => (props.pt ? props.pt : null)};
  padding-bottom: ${props => (props.pb ? props.pb : null)};
  padding-left: ${props => (props.pl ? props.pl : null)};
  padding-right: ${props => (props.pr ? props.pr : null)};
  align-items: ${props => (props.align ? props.align : "flex-start")};
  height: ${props => props.height};
  justify-content: flex-end;
  margin-top: ${props => (props.mt ? props.mt : null)};
`
const LeftColumn = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${props => (props.height ? props.height : null)};
  width: ${props => (props.width ? props.width : "60%")};
  padding-top: ${props => (props.pt ? props.pt : null)};
  padding-bottom: ${props => (props.pb ? props.pb : null)};
  padding-right: ${props => (props.pr ? props.pr : null)};
  padding-left: ${props => (props.pl ? props.pl : null)};
  align-items: ${props => (props.alignItems ? props.alignItems : null)};
`
const BackWrapper = styled("div")`
  display: flex;
  flex-direction: column;
`

class ProjectDeskotp extends Component {
  render() {
    const {
      text_color,
      title,
      desc1,
      desc2,
      desc3,
      heroDesktop,
      list,
      threeImages,
    } = this.props

    const bigImage = list && list.length <= 2

    return list ? (
      <Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Wrapper id="wrapper">
          <ColumnWrapper first height="95vh" mb={"8vw"}>
            <LeftColumn>
              <BigText
                color={text_color}
                fontSize={"4vw"}
                mb={"2vw"}
                underline={true}
                text={desc1}
              ></BigText>
              <BackWrapper>
                <SmallText
                  color={text_color}
                  mr={"12vw"}
                  mb={"1vw"}
                  text={desc2}
                ></SmallText>
                <BackButtonDesktop color={text_color}></BackButtonDesktop>
              </BackWrapper>

              {desc3 && (
                <SmallText
                  color={text_color}
                  alignText={"right"}
                  p="absolute"
                  text={desc3}
                ></SmallText>
              )}
            </LeftColumn>

            <RightColumn align="flex-end" mt={"auto"} pl="3vw" id="rightColumn">
              {heroDesktop && (
                <GatsbyImage src={heroDesktop.fluid}></GatsbyImage>
              )}
            </RightColumn>
          </ColumnWrapper>

          {/* Page 2 */}

          {threeImages ? (
            <ThreeImages list={list}></ThreeImages>
          ) : (
            <Fragment>
              <ColumnWrapper alignItems={"flex-end"}>
                {/* absolute positions */}

                {bigImage && (
                  <BaseImage
                    src={list[0].image}
                    width={"60%"}
                    position={"absolute"}
                    top="0"
                    left="0"
                  />
                )}
                {bigImage && (
                  <BaseImage
                    src={list[1].image}
                    position="absolute"
                    right="0"
                    top="0"
                    width="20vw"
                  />
                )}
                {list && list.length >= 4 && (
                  <BaseImage
                    src={list[0].image}
                    width={"25%"}
                    position={"absolute"}
                    top="0"
                    left="0"
                  />
                )}
                {/* absolute positions */}

                {!bigImage && (
                  <LeftColumn
                    pt={"2vw"}
                    pb={bigImage ? "6vw" : "3vw"}
                    alignItems={"flex-end"}
                    width={bigImage ? "90%" : "45%"}
                  >
                    {list && list.length === 3 ? (
                      <BaseImage
                        src={list[0].image}
                        alt="img"
                        width={"60%"}
                        mr={"3vw"}
                      />
                    ) : (
                      <BaseImage src={list && list[1].image} width={"40%"} />
                    )}
                  </LeftColumn>
                )}
                {!bigImage && (
                  <RightColumn
                    flexDirection={"row"}
                    pt={"6vw"}
                    pr={"3vw"}
                    pl={"3vw"}
                    pb={"6vw"}
                    width={"55%"}
                  >
                    <BaseImage
                      src={list.length >= 4 ? list[2].image : list[1].image}
                      alt="img"
                      width={"30%"}
                      mr={"2vw"}
                      ml={"2vw"}
                    />

                    <BaseImage
                      src={list.length >= 4 ? list[3].image : list[2].image}
                      width={"60%"}
                    />
                  </RightColumn>
                )}
              </ColumnWrapper>
              {/* wide fifth and sixth image */}
              {list.length >= 5 && (
                <ColumnWrapper mt="4vw" mb="4vw">
                  <BaseImage src={list[4].image} width={"65.6%"} />
                  {list.length === 6 && (
                    <BaseImage
                      src={list[5].image}
                      objectFit="contain"
                      display="flex"
                      alignSelf="flex-start"
                      width={"20%"}
                      ml="2vw"
                    />
                  )}
                </ColumnWrapper>
              )}
            </Fragment>
          )}
        </Wrapper>
      </Fragment>
    ) : null
  }
}

export default ProjectDeskotp
