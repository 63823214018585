import React, { Component, Fragment } from "react"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"
import BackButtonDesktop from "../components/backButtonDesktop"
import BigText from "../components/text/bigtext"
import SmallText from "../components/text/smalltext"
import GatsbyImage from "../components/gatsbyImage"
import NewImage from "../components/newImage"
import { Above, Below } from "../Breakpoints"
import BackButtonMobile from "./backbuttonMobile"
import BaseImage from "../components/baseImage"

const SectionWrapper = styled("div")`
  position: relative;
  display: flex;
  transform: translate3d(0, 0, 0);
  flex-direction: column;
  margin-bottom: 12vw;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: ${props => (props.mb ? props.mb : null)};
`

const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 300ms ease-in;
  animation-fill-mode: forwards;
  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    10% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }
`
const ColumnWrapper = styled("div")`
  max-height: 100vh;
  position: relative;
  width: 100%;
  margin-top: ${props => (props.mt ? props.mt : null)};
  margin-bottom: ${props => (props.mb ? props.mb : null)};
  display: flex;
  align-items: ${props => (props.alignItems ? props.alignItems : null)};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : null};
  padding-right: ${props => (props.pr ? props.pr : null)};
  min-height: ${props => (props.height ? props.height : null)};
  @media screen and (max-width: 1025px) {
    min-height: ${props => (props.first ? "50vh" : null)};
  }
  @media screen and (max-height: 600px) {
    height: 150vh;
    max-height: initial;
  }
`
const RightColumn = styled("div")`
  position: relative;
  display: flex;
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : "column"};
  width: ${props => (props.width ? props.width : "40%")};
  padding-top: ${props => (props.pt ? props.pt : null)};
  padding-bottom: ${props => (props.pb ? props.pb : null)};
  padding-left: ${props => (props.pl ? props.pl : null)};
  padding-right: ${props => (props.pr ? props.pr : null)};
  align-items: ${props => (props.align ? props.align : "flex-start")};
  height: ${props => props.height};
  justify-content: flex-end;
  margin-top: ${props => (props.mt ? props.mt : null)};
`
const LeftColumn = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${props => (props.height ? props.height : null)};
  width: ${props => (props.width ? props.width : "60%")};
  padding-top: ${props => (props.pt ? props.pt : null)};
  padding-bottom: ${props => (props.pb ? props.pb : null)};
  padding-right: ${props => (props.pr ? props.pr : null)};
  padding-left: ${props => (props.pl ? props.pl : null)};
  align-items: ${props => (props.alignItems ? props.alignItems : null)};
`
const BackWrapper = styled("div")`
  display: flex;
  flex-direction: column;
`
const FullPageImageWrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  width: ${props => (props.width ? props.width : "100%")};
  padding: ${props => (props.p ? props.p : null)};
`
const FullPageHeadingWrapper = styled("div")`
  position: absolute;
  top: 10vh;
  left: 5vw;
  transform: rotate(25deg);
  transform-origin: 0 0;
  z-index: 10;
`
const MobileHeroImageWrapper = styled("div")`
  margin-bottom: 12vw;
`

class ProjectDeskotp extends Component {
  render() {
    const {
      text_color,
      title,
      desc1,
      desc2,
      desc3,
      heroDesktop,
      listDesktop,
      listMobile,
    } = this.props

    return listDesktop && listMobile ? (
      <Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <Wrapper>
          {/* Desktop */}
          <Above breakpoint="md">
            <ColumnWrapper first height="95vh" mb={"12vw"}>
              <LeftColumn>
                <BigText
                  color={text_color}
                  fontSize={"4vw"}
                  mb={"2vw"}
                  underline={true}
                  text={desc1}
                ></BigText>
                <BackWrapper>
                  <SmallText
                    color={text_color}
                    mr={"12vw"}
                    mb={"1vw"}
                    text={desc2}
                  ></SmallText>
                  <BackButtonDesktop color={text_color}></BackButtonDesktop>
                </BackWrapper>

                {desc3 && (
                  <SmallText
                    color={text_color}
                    alignText={"right"}
                    p="absolute"
                    text={desc3}
                  ></SmallText>
                )}
              </LeftColumn>

              <RightColumn
                align="flex-end"
                mt={"auto"}
                pl="3vw"
                id="rightColumn"
              >
                {heroDesktop && (
                  <GatsbyImage src={heroDesktop.fluid}></GatsbyImage>
                )}
              </RightColumn>
            </ColumnWrapper>
            {listDesktop.map((item, index) => (
              <SectionWrapper mb={"12vw"} key={index}>
                {item.title && (
                  <FullPageHeadingWrapper>
                    <BigText
                      color={text_color}
                      fontSize={"4vw"}
                      underline={true}
                      text={item.title}
                    ></BigText>
                  </FullPageHeadingWrapper>
                )}

                <FullPageImageWrapper width="70%">
                  <BaseImage
                    wrapperStyle={{
                      userSelect: "none",
                      pointerEvents: "none",
                      maxHeight: "100%",
                      width: "100%",
                    }}
                    src={item.image}
                  />
                </FullPageImageWrapper>
              </SectionWrapper>
            ))}
          </Above>

          {/* Mobile */}

          <Below breakpoint="md">
            <BigText
              color={text_color}
              mb={"3vw"}
              underline={true}
              text={desc1}
            ></BigText>
            {desc2 && (
              <SmallText
                color={text_color}
                mr={"6vw"}
                mb={"6vw"}
                text={desc2}
              ></SmallText>
            )}
            {desc3 && (
              <SmallText
                color={text_color}
                alignText={"right"}
                mr={"6vw"}
                mb={"6vw"}
                text={desc3}
              ></SmallText>
            )}
            <MobileHeroImageWrapper>
              <NewImage
                wrapperStyle={{
                  userSelect: "none",
                  pointerEvents: "none",
                  maxHeight: "100%",
                  width: "100%",
                }}
                src={heroDesktop.fluid}
              />
            </MobileHeroImageWrapper>
            {listMobile.map((item, index) => (
              <SectionWrapper
                mb={index === listMobile.length - 1 ? "0" : "12vw"}
                key={index}
              >
                {item.title && (
                  <FullPageHeadingWrapper>
                    <BigText
                      color={text_color}
                      fontSize={"4vw"}
                      underline={true}
                      text={item.title}
                    ></BigText>
                  </FullPageHeadingWrapper>
                )}

                <FullPageImageWrapper width="85%">
                  <BaseImage
                    wrapperStyle={{
                      userSelect: "none",
                      pointerEvents: "none",
                      maxHeight: "100%",
                      width: "100%",
                    }}
                    src={item.image}
                  />
                </FullPageImageWrapper>
              </SectionWrapper>
            ))}
            <BackButtonMobile color={text_color}></BackButtonMobile>
          </Below>
        </Wrapper>
      </Fragment>
    ) : null
  }
}

export default ProjectDeskotp
