import React, { Component, Fragment } from "react"
import styled from "../../components/styled"
//import GatsbyImage from "../gatsbyImage"
import BaseImage from "../baseImage"

const ColumnWrapper = styled("div")`
  max-height: 100vh;
  position: relative;
  width: 100%;
  margin-top: ${props => (props.mt ? props.mt : null)};
  margin-bottom: ${props => (props.mb ? props.mb : null)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: ${props => (props.pr ? props.pr : null)};
  height: ${props => (props.height ? props.height : null)};
`

const Wrapper = styled("div")`
  max-height: 100vh;
  position: relative;
  display: flex;
  margin-bottom: 6vw;
  margin-top: 2vw;
  @media screen and (max-width: 1025px) {
    height: 50vh;
  }
`

const ImgWrapper = styled("div")`
  display: flex;
`

class ThreeImages extends Component {
  render() {
    const { list } = this.props

    return list ? (
      <Fragment>
        {list.length >= 3 && (
          <Wrapper mx="2vw">
            <ImgWrapper>
              <BaseImage
                src={list[0].image}
                wrapperStyle={{
                  userSelect: "none",
                  pointerEvents: "none",
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                }}
              />
            </ImgWrapper>
            <ImgWrapper ml="2vw" mr="2vw">
              <BaseImage
                src={list[1].image}
                wrapperStyle={{
                  userSelect: "none",
                  pointerEvents: "none",
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                }}
              />
            </ImgWrapper>
            <ImgWrapper>
              <BaseImage
                src={list[2].image}
                wrapperStyle={{
                  userSelect: "none",
                  pointerEvents: "none",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </ImgWrapper>
          </Wrapper>
        )}

        {/* wide and small image */}
        {list.length >= 4 && (
          <ColumnWrapper mt="4vw" mb="4vw">
            <BaseImage src={list[3].image} width={"65.333%"} />
            {list.length === 5 && (
              <BaseImage
                src={list[4].image}
                width={"20%"}
                ml="auto"
                mr="auto"
              />
            )}
          </ColumnWrapper>
        )}
      </Fragment>
    ) : null
  }
}

export default ThreeImages
