import React, { Component, Fragment } from "react"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"
import BackButtonMobile from "./backbuttonMobile"
import BigText from "./text/bigtext"
import SmallText from "./text/smalltext"
//import GatsbyImage from "../components/gatsbyImage"
import BaseImage from "../components/baseImage"

const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;

  animation: fadeIn 450ms ease-in;
  animation-fill-mode: forwards;
  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    10% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }
`

class ProjectMobile extends Component {
  /* state = {
    instagram: null,
  } */
  /*  componentDidMount() {
    
     let is_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
      navigator.userAgent
    )
    is_uiwebview && this.setState({ instagram: true }) 
  } */
  render() {
    const { text_color, title, desc1, desc2, desc3, list } = this.props
    /* const { instagram } = this.state */

    return list ? (
      <Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Wrapper>
          <BigText
            color={text_color}
            /* mt={instagram ? "10vw" : null} */
            mb={"3vw"}
            underline={true}
            text={desc1}
          ></BigText>
          {desc2 && (
            <SmallText
              color={text_color}
              mr={"6vw"}
              mb={"6vw"}
              text={desc2}
            ></SmallText>
          )}
          {desc3 && (
            <SmallText
              color={text_color}
              alignText={"right"}
              mr={"6vw"}
              mb={"6vw"}
              text={desc3}
            ></SmallText>
          )}

          {list &&
            list.map((item, index) => (
              <BaseImage key={index} src={item.image} mb="6vw" />
            ))}

          <BackButtonMobile color={text_color}></BackButtonMobile>
        </Wrapper>
      </Fragment>
    ) : null
  }
}

export default ProjectMobile
