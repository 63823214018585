import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const Image = styled("div")`
  position: ${props => (props.p ? props.p : "relative")};
  right: ${props => (props.r ? props.r : null)};
  left: ${props => (props.l ? props.l : null)};
  top: ${props => (props.t ? props.t : null)};
  display: flex;
  width: ${props => (props.width ? props.width : "100%")};
  height: 100%;
  max-height: ${props => (props.maxHeight ? props.maxHeight : null)};
  margin-top: ${props => (props.mt ? props.mt : null)};
  margin-bottom: ${props => (props.mb ? props.mb : null)};
  margin-right: ${props => (props.mr ? props.mr : null)};
  margin-left: ${props => (props.ml ? props.ml : null)};
`

export default ({ ...props }) => (
  <Image {...props}>
    <div style={{ width: "100%" }}>
      <Img fluid={props.src} durationFadeIn={200} alt="img" />
    </div>
  </Image>
)
